<template>
  <div>
    <div v-if="clientRequests.length">
      <div class="border-bottom pb-2 small text-muted">
        You have {{ clientRequests.length }}
        coaching request{{ clientRequests.length > 1 ? 's' : '' }}
      </div>
      <ul class="list-group list-group-flush">
        <ClientRequestListItem
          v-for="(request, requestIndex) in clientRequests"
          :key="`key-${requestIndex}`"
          :request="request"
          class="list-group-item"
        ></ClientRequestListItem>
      </ul>
    </div>
    <div v-else class="small text-muted">No request found</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ClientRequestList',
  components: {
    ClientRequestListItem: () => import('@/components/ClientRequestListItem'),
  },
  computed: {
    ...mapState('client', ['clientRequests']),
  },
};
</script>
